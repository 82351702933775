.quizBuilder {
    @include siteWidth;

    width: 100%;
    padding: 40px 20px;

    h3 {
        font-weight: bold;
    }
}
