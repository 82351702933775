.sharedBuilder {
    position: relative;

    &.loading {
        &:before {
            content: 'Fetching your questions...';
            font-size: 32px;
            background-color: rgba(255, 255, 255, 0.95);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    form {
        margin: 40px 0;
    }

    div[role='group'] {
        border: none;
        padding: 0;
        margin: 0 auto 20px;
        display: flex;
        justify-content: space-between;

        @include mq($until: tablet) {
            flex-direction: column;
        }

        label {
            width: 20%;
            text-align: left;
            display: flex;
            flex-direction: column;

            @include mq($until: tablet) {
                width: 100%;
                margin-bottom: 15px;
            }
        }

        span {
            margin-bottom: 5px;
            display: inline-block;
        }

        input[type=number] {
            padding: 7px 5px;
            border: 1px solid rgba(0, 0, 0, 0.9);
        }
    }

    @include mq($until: tablet) {
        button {
            width: 100%;
            margin-bottom: 15px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &__question {
        display: flex;
        text-align: left;
        margin-bottom: 15px;

        &-number {
            font-weight: 600;
            margin-right: 20px;
        }

        &-question {
            display: flex;

            span {
                line-height: 30px;
            }
        }

        &-answer {
            .spacer {
                &:last-of-type {
                    display: none;
                }
            }
        }

        &-type {
            font-weight: bold;
            display: inline-block;
            margin-right: 5px;
            font-size: 21px;
        }

        @include mq($until: tablet) {
            margin-bottom: 30px;

            &-question {
                flex-direction: column;
            }

            p {
                span {
                    display: block;
                    width: 100%;

                    &:not(.sharedBuilder__question-type) {
                        margin-bottom: 15px;
                    }
                }
            }
        }

        span {
            &.hidden {
                color: $danger;
                font-style: italic;
            }
        }
    }
}
