.complexBuilder__item {
    display: flex;
    justify-content: space-between;

    label {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    select {
        margin: 10px 0 0;
        padding: 6px 5px;
        height: 34px;
        border: 1px solid rgba(0, 0, 0, 0.9);
        border-radius: 0;
    }

    &-round-number {
        text-align: left;
        font-size: 22px;
        margin-bottom: 15px;
    }
}
