.complexBuilder {
    .instruction {
        position: relative;
    }

    &__addRound {
        margin-right: 15px !important;
        background-color: $success;
    }

    &__error-message {
        display: block;
        max-width: 600px;
        margin: 40px auto 25px;
        color: #c90e0e;
        font-weight: bold;
    }
}
