/* Banner */
.banner {
    height: 450px;
    background-image: url('./header-background.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    position: relative;
    color: #fff;
    justify-content: center;
    align-items: center;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: .7;
        background-color: #000;
        z-index: 0;
    }

    h1, h2, h3, h4, p {
        @include siteWidth;

        position: relative;
        z-index: 1;
        max-width: 800px;
    }

    img {
        @include siteWidth;

        margin-bottom: 30px;
        max-width: 80%;
        position: relative;
        z-index: 1;
    }

    h1 {
        margin-bottom: 20px;
        font-weight: 600;
    }

    h3 {
        font-weight: 400;
    }

    .vh {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}
